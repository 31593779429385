@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;800&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
}

a{
    text-decoration: none;
    color: gold;
}